import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import typefaceFont from 'three/examples/fonts/helvetiker_regular.typeface.json'

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Gsap
const parameters = {
  //color: 0xe6ffe6,
  color: 0xe6ffff,
  spin: () => {
    gsap.to(mesh.rotation, { duration: 1, y: mesh.rotation.y + 10 })
  }
}

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const cubeTextureLoader = new THREE.CubeTextureLoader()
const matcapTexture = textureLoader.load('textures/matcaps/8.png')
const textureLoader2 = new THREE.TextureLoader()
const matcapTexture2 = textureLoader2.load('textures/matcaps/2.png')

const environmentMapTexture = cubeTextureLoader.load([
  '/textures/environmentMaps/3/px.jpg',
  '/textures/environmentMaps/3/nx.jpg',
  '/textures/environmentMaps/3/py.jpg',
  '/textures/environmentMaps/3/ny.jpg',
  '/textures/environmentMaps/3/pz.jpg',
  '/textures/environmentMaps/3/nz.jpg'
])


/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
scene.add(ambientLight)

const light = new THREE.PointLight(0xffffff, 0.5)
light.position.x = 2
light.position.y = 3
light.position.z = 4
scene.add(light)

/**
 * Fonts
 */
const fontLoader = new FontLoader()

fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) =>
    {
        // Material
        const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
        const material2 = new THREE.MeshMatcapMaterial({ matcap: matcapTexture2 })

        // Text
        const textGeometry = new TextGeometry(
            `Paul Oosterhoff`,
            {
                font: font,
                size: 0.1,
                height: 0.03,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 2
            }
        )
      textGeometry.center()

        const text = new THREE.Mesh(textGeometry, material2)
        text.rotation.y = 1
        scene.add(text)
        text.position.y = 1

      // Text
        const textGeometry2 = new TextGeometry(
            `Web Developer`,
            {
                font: font,
                size: 0.15,
                height: 0.03,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 2
            }
        )
        textGeometry2.center()

        const text2 = new THREE.Mesh(textGeometry2, material)
        text2.rotation.y = 0.5
        text2.position.y = 0.5
        scene.add(text2)

        // Donuts
        // const donutGeometry = new THREE.TorusBufferGeometry(0.3, 0.2, 32, 64)
        const donutGeometry = new THREE.ConeBufferGeometry(0.5, 0.5, 8, 1)

        for(let i = 0; i < 200; i++)
        {
            const donut = new THREE.Mesh(donutGeometry, material)
            donut.position.x = (Math.random() - 0.5) * 10
            donut.position.y = (Math.random() - 0.5) * 10
            donut.position.z = (Math.random() - 0.5) * 10
            donut.rotation.x = Math.random() * Math.PI
            donut.rotation.y = Math.random() * Math.PI
            const scale = Math.random() *.5
            donut.scale.set(scale, scale, scale)

            scene.add(donut)
        }
    }
)
const material = new THREE.MeshStandardMaterial({ color: parameters.color })
material.metalness = 1.0
material.roughness = 0.0
material.envMap = environmentMapTexture
material.side = THREE.DoubleSide

// Plane
const plane = new THREE.Mesh(
  new THREE.PlaneGeometry(.6, .6, 100, 100),
  material
)
plane.geometry.setAttribute('uv2', new THREE.BufferAttribute(plane.geometry.attributes.uv.array, 2))
plane.position.set(-0.7, 1.7, 0)

// Torus
const torus = new THREE.Mesh(
  new THREE.TorusKnotGeometry(0.5, 0.2, 84, 128),
  material
)
torus.geometry.setAttribute('uv2', new THREE.BufferAttribute(torus.geometry.attributes.uv.array, 2))
torus.position.set(0, -1, 0)

scene.add(plane, torus)

/**
 * Sizes
 */
const layBanNav = document.querySelector('header.main-header').clientHeight
const layFooter = document.querySelector('footer.main-footer').clientHeight
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight - layBanNav - layFooter
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight - layBanNav - layFooter

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 1
camera.position.y = 1
camera.position.z = 3
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()
const animate = () =>
{
  const elapsedTime = clock.getElapsedTime()
  // Update objects
  plane.rotation.y = 0.1 * elapsedTime
  torus.rotation.y = 0.1 * elapsedTime

  plane.rotation.x = 0.15 * elapsedTime
  torus.rotation.x = 0.2 * elapsedTime
  // Call tick again on the next frame
  window.requestAnimationFrame(animate)
  // Render
  renderer.render(scene, camera)
}
animate()